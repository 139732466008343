@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.cdnfonts.com/css/broadsheet-ldo");
@import url("https://fonts.googleapis.com/css2?family=Gasoek+One&display=swap");

body {
  font-family: "Broadsheet LDO", sans-serif;
  background-color: #262626;
}

.homebg {
  background-image: url(./assets/frogback.png);
  background-size: cover;
  background-position: top;
}

.gasoek {
  font-family: "Gasoek One", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.surf {
  background-image: url(./assets/pumpback.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.waterbg {
  background-image: url(./assets/waterbg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  z-index: 50;
  /* margin-top: -300px; */
}

.dimback {
  background-image: url(./assets/dimback.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 50;
  /* margin-top: -300px; */
}

/* src/components/Surf.css */
@keyframes move {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(20px);
  } /* Adjust the distance as needed */
  100% {
    transform: translateX(0);
  }
}

.moving {
  animation: move 2s ease-in-out infinite; /* Adjust duration as needed */
}

.bobbing {
  animation: bob 2s ease-in-out infinite;
}

@keyframes bob {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(
      -20px
    ); /* Adjust the value for more or less bobbing */
  }
}
